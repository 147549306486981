.slider-container {
  width: 905px;
  height: 509px;
  margin: auto;
  margin-bottom: 50px;
  padding: 0;

  @media screen and (max-width: 920px) {
    width: 100% !important;
    height: 420px;
  }

  &__inner {
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;
  }

  &__swiper {
    width: 100%;
    height: 100%;
  }

  &__swiper-arrow {
    z-index: 1;
    position: absolute;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;

    padding: 4px;

    background-color: rgba(224, 225, 226, .7);
    color: #7d7d7d;

    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }

    &_left { left: 20px; }
    &_right { right: 20px; }
  }

  &--img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
  }

  &--text {
    font-family: "Montserrat", serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    margin-top: 20px;
    padding-left: 1rem;
    position: relative;
    margin-bottom: 0;
    color: black;

    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 2px;
      background: #13b5ac;
      height: 90%;
      left: 0;
      top: 3px;
    }

    a { color: red !important; }
  }
}

@media screen and (max-width: 1023px) {

  .slider-container {
    &--text {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .slider-container {
    height: 270px;
    margin-bottom: 60px;

    &--text {
      margin-left: 20px;
    }
  }
}
